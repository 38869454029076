import type { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import { createStyles } from "antd-style";
import NumberConverter from "../../../utils/NumberFormatter";

interface Property {
  id: number;
  uid: string;
  revenue: number | string;
  target: number | string;
  comparison_percent: string | number;
  comparison_value: number | string;
  occupancy: number;
  adj_occupancy: number;
  adr: number | string;
  revpar: number | string;
  avg_los: number | string;
  blocked_nights: number;
  owner_stay_count: number;
}

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    antd-table {
      antd-table-container {
        antd-table-body,
        antd-table-content {
          scrollbar-width: thin;
          scrollbar-color: unset;
        }
      }
    }
  `,
}));

const PerformanceTable: React.FC<{
  data: Property[];
  isLoading: boolean;
}> = ({ data, isLoading }) => {
  const { styles } = useStyle();

  const baseColumns: ColumnsType<Property> = [
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      width: "20%",
      fixed: "left",
    },

    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.revenue && record.revenue !== "N/A" && record.revenue !== "0.00" ? (
            <>
              <span>AED </span>
              <NumberConverter value={record.revenue} />
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.target && record.target !== "N/A" && record.target !== "0.00" ? (
            <>
              <span>AED </span>
              <NumberConverter value={record.target} />
            </>
          ) : (
            " "
          )}
        </div>
      ),
    },
    {
      title: "Variance",
      dataIndex: "comparison_value",
      key: "comparison_value",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <>
          {record.comparison_value && record.comparison_value !=="N/A" ? (
            <>
              <span>AED </span>
              <NumberConverter value={record.comparison_value} />
            </>
          ) : (
            ""
          )}
          <div>
            {record.comparison_percent &&
            record.comparison_percent !== "N/A" ? (
              <span>
                <NumberConverter value={record.comparison_percent} />%
              </span>
            ) : (
              ""
            )}
          </div>
        </>
      ),
    },
    {
      title: "Occupancy",
      dataIndex: "occupancy",
      key: "occupancy",
      width: "10%",
      align: "center",
    },
    {
      title: "Adj Occupancy",
      dataIndex: "adj_occupancy",
      key: "adj_occupancy",
      width: "10%",
      align: "center",
    },
    {
      title: "ADR",
      dataIndex: "adr",
      key: "adr",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.adr && record.adr !== "N/A" && record.adr !== "0.00" ?
          <><span>AED </span><NumberConverter value={record.adr} /></>
          : " "
    }
        </div>
      ),
    },
    {
      title: "RevPAR",
      dataIndex: "revpar",
      key: "revpar",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.adr && record.revpar !== "N/A" && record.revpar !== "0.00" ?
          <><span>AED </span><NumberConverter value={record.revpar} /></>
          : " "
    }
        </div>
      ),
    },
    {
      title: "Length of Stay",
      dataIndex: "avg_los",
      key: "avg_los",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.avg_los && record.avg_los !== "N/A" ?
          <><NumberConverter value={record.avg_los} /></>
          : " "
    }
        </div>
      ),
    },
    {
      title: "Blocked Nights",
      dataIndex: "blocked_nights",
      key: "blocked_nights",
      width: "10%",
      align: "center",
    },
    {
      title: "Owners Stay",
      dataIndex: "owner_stay_count",
      key: "owner_stay_count",
      width: "10%",
      align: "center",
    },
  ];

  const columns = [...baseColumns];
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      bordered
      className={`font-semibold ${styles.customTable}`}
      pagination={false}
      loading={isLoading}
      scroll={{ x: "calc(850px + 40%)" }}
    />
  );
};

export default PerformanceTable;
