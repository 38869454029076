import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
const Home = () => {

  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const navigate = useNavigate();

  return (
    <>
      <div className="w-4/5 mx-auto h-screen overflow-hidden">
        <div className="min-w-full inline-flex pt-6">
          <div className="place-content-start inline-flex w-full">
            <div>
              <img src="vividity main.png" height={200} width={180}></img>
            </div>
          </div>
          <div className="text-white rounded-lg font-bold my-auto px-2 py-2" style={{ backgroundColor: "#0066b2" }}>
          {accessToken ? (
            <Button
            onClick={() => navigate("/dashboard/home")}
            className="!text-white px-3 text-nowrap !font-semibold"
          >
            Go to Dashboard
          </Button>
        ) : (
            <Link
              type="submit"
              to="/login"
              className="px-3 py-2"
            >
              Login
            </Link>
        )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 w-full py-20">
          <div className="text-center px-5 pt-10">
            <h1 className="text-4xl pb-5 font-medium text-left">
            Maximize Your{" "}
              <span className="" style={{ color: "#0066b2" }}>
                <b>Revenue </b>
              </span>{" "}
              Potential with Our Innovative Solution
            </h1>
            <p className="text-lg font-thin text-left">
            Empower your business with intelligent pricing, real-time data, and comprehensive reporting. Vividity helps you make data-driven decisions that maximize your revenue and streamline operations.
            </p>
            <div className="flex pl-4 inline-flex text-white mt-8 py-2 font-bold mx-0 w-full justify-start">
              <Link
                type="submit"
                className="px-5 py-3 rounded-lg shadow-lg"
                to={""}
                style={{ backgroundColor: "#0066b2" }}
              >
                Get Started
              </Link>
              <Link
                type="submit"
                color="inherit"
                className="px-5 py-3 bg-blue-100 rounded-lg shadow-lg ml-3 text-blue-800 border"
                to={""}
              >
                Documentation
              </Link>
            </div>
          </div>
          <div className="">
            <img
              src="due-deligenace-banner (1).png"
              width={600}
              height={600}
            ></img>
          </div>
        </div>
      </div>
      {/* <div
        className="flex inline-flex bg-white text-blue-800 text-sm fixed w-full gap-4 px-2 justify-between items-center"
        style={{ bottom: "0" }}
      >
        <div
          color="inherit"
          className="border-white align-left w-fit font-medium tracking-wider cursor-pointer"
          style={{ fontSize: 12 }}
        >
          vividity.com
        </div>
        <div
          className="text-xs border-slate-50 p-2 text-blue-800 !font-smeibold"
          style={{ fontSize: 10, whiteSpace: "nowrap" }}
        >
          Copyright © Keleno Labs Pvt. Ltd. All rights reserved.
        </div>
      </div> */}
    </>
  );
};

export default Home;
