import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { formatNumberWithCommas } from "../../utils";

class CustomizedAxisTick extends PureComponent<any, any> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor="end"
          fill="#666"
          transform="rotate(-50)"
        >
          {replaceLabel(payload.value)}
        </text>
      </g>
    );
  }
}

interface AverageRevenueProps {
  data: {
    name: string;
    Revenue: number;
    ADR: number;
    LOS: number;
    "Lead-time": number;
    "PM Fees": number;
  }[];
  dataKey: "Revenue" | "ADR" | "LOS" | "Lead-time" | "PM Fees";
}

const formatNumber = (num: number): string => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "k";
  }
  return num.toString();
};

// Helper function to replace "LOS" and "Lead-time" with "bookings"
const replaceLabel = (label: string) => {
  if (label === "LOS" || label === "Lead-time") {
    return "Bookings";
  }
  return label;
};

export default class AverageRevenue extends PureComponent<AverageRevenueProps> {
  getMaxValue = () => {
    const { data, dataKey } = this.props;
    return Math.max(...data?.map((item) => item[dataKey])) || 0;
  };

  render() {
    const { data, dataKey } = this.props;
    const maxValue = this.getMaxValue();
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 15,
            left: 0,
            bottom: 0,
          }}
          barSize={15}
          style={{ marginLeft: "auto", marginRight: "auto", paddingLeft: 5 }}
        >
          <XAxis
            dataKey="name"
            scale="point"
            className="text-xs"
            interval={0}
            tick={
              dataKey === "LOS" || dataKey === "Lead-time" ? undefined : (
                <CustomizedAxisTick />
              )
            }
            tickFormatter={replaceLabel} // Apply label replacement for X-axis
            label=""
          />

          <YAxis
            domain={[0, Math.ceil(maxValue * 1.2)]}
            tickCount={11}
            className="!pl-4"
            allowDataOverflow={true}
            tickFormatter={(tick) => formatNumber(tick)}
          />

          <Tooltip
            formatter={(value, name) => [formatNumberWithCommas(value as number), replaceLabel(name as string)]} // Apply label replacement in Tooltip
          />
          <Legend content={() => null} />
          <CartesianGrid strokeDasharray="3 3" />

          <Bar
            dataKey={dataKey}
            fill="#41645c"
            background={{ fill: "#eee" }}
            className="display-none"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
