import ListingsTable from "./PropertyTable";
import { Container, Typography } from "@mui/material";
import ReusablePagination from "../../../components/PaginationComponent";
import { Button, Input } from "antd";
import { useEffect, useState, useCallback } from "react";
import useDebounce from "../../../hooks/useDebounce";
import useSearchPagination from "../../../hooks/useSearchPaginationFetch";
import { LISTINGTYPES } from "../portfolio/utils";
import ToggleButton from "../../../components/ToggleButton";

import { fetch } from "../../../services/targetAPI";
import { ITEMS_PER_PAGE } from "../utils";
import ConfigTable from "./ListingConfigureTable";

const TargetDashboard = () => {
  const { Search } = Input;
  const [modalOpen, setModalOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [activeSelection, setActiveSelection] = useState<string>("active");

  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);

  const {
    data,
    setData,
    searchValue,
    setSearchValue,
    currentPageNumber,
    setCurrentPageNumber,
    totalItems,
    isLoading,
    currentPageSize,
    setCurrentPageSize,
    setTotalItems,
    setIsLoading,
  } = useSearchPagination("", ITEMS_PER_PAGE);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const fetchTargets = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        currentPageNumber,
        currentPageSize,
        activeSelection,
        debouncedSearchValue
      );
      setData(response?.data?.results);
      setTotalItems(response?.data?.count);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPageNumber,
    currentPageSize,
    activeSelection,
    debouncedSearchValue,
  ]);

  const onConfigureButtonClick = () => {
    setConfigureTableOpen(true);
  };

  const onListingButtonClick = () => {
    setConfigureTableOpen(false);
  };

  useEffect(() => {
    if (isInitialLoad) {
      fetchTargets();
      setIsInitialLoad(false);
    }
  }, [fetchTargets, isInitialLoad]);

  useEffect(() => {
    if (!isInitialLoad) {
      setData([]);
      setTotalItems(0);
      setCurrentPageNumber(1);
      currentPageNumber === 1 && fetchTargets();
    }
  }, [currentPageSize, activeSelection, debouncedSearchValue]);

  useEffect(() => {
    if (!modalOpen && !isInitialLoad) {
      setData([]);
      fetchTargets();
    }
  }, [currentPageNumber, modalOpen]);

  return (
    <Container
      className="p-4 flex flex-col space-y-4 bg-gray-100 min-h-screen min-w-full !mx-0"
      maxWidth="xl"
    >
      <div className="flex inline-flex w-full justify-between gap-4 items-center">
      {configureTableOpen == false ? (
        <Typography variant="h4" className="!text-gray-500">
          Listings Dashboard
        </Typography>
       ) :
        <Typography variant="h4" className="!text-gray-500">
          Listings Config
        </Typography>
      }
        {configureTableOpen == false ? (
          <Button
            className="border-0 !bg-gray-100 text-blue-700 shadow-none font-semibold text-xl px-0"
            onClick={onConfigureButtonClick}
          >
            Config
          </Button>
        ) : (
          <Button
            className="border-0 !bg-gray-100 text-blue-700 shadow-none font-semibold text-xl px-0"
            onClick={onListingButtonClick}
          >
            Dashboard
          </Button>
        )}
      </div>
      {configureTableOpen == false ? (
        <div className="flex inline-flex w-full justify-between space-x-4 items-center bg-white px-5 py-3 mt-3 rounded-lg shadow-lg">
          <Search
            placeholder="Search Units"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="w-1/4"
          />
          <ToggleButton
            Choices={LISTINGTYPES}
            selection={activeSelection}
            setSelection={setActiveSelection}
          />
        </div>
      ) : (
        ""
      )}
      <div className="relative">
        <div className="overflow-x-auto rounded-lg shadow-lg">
          {configureTableOpen == false ? (
            <ListingsTable
              data={data}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              isLoading={isLoading}
              fetchTargets={fetchTargets}
            />
          ) : (
            <ConfigTable data={data} isLoading={isLoading} propertyID={""} />
          )}
          <ReusablePagination
            currentPage={currentPageNumber}
            onPageSizeChange={setCurrentPageSize}
            pageSize={currentPageSize}
            totalItems={totalItems}
            onPageChange={setCurrentPageNumber}
          />
        </div>
      </div>
    </Container>
  );
};

export default TargetDashboard;
