import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { formatNumberWithCommas } from "../../utils";

interface BookingsProps {
  data: {
    name: string;
    Bookings: number;
    Nights: number;
    RevPAR: number;
    Occupancy: string;
    blocked: number;
  }[];
  dataKey: "Bookings" | "Nights" | "RevPAR" | "Occupancy" | "blocked";
}

const formatNumber = (num: number): string => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "k";
  }
  return num.toString();
};

class CustomizedAxisTick extends PureComponent<any, any> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor="end"
          fill="#666"
          transform="rotate(-50)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export default class Bookings extends PureComponent<BookingsProps> {
  getMaxValue = () => {
    const { data, dataKey } = this.props;
    const maxi =
      dataKey === "Occupancy"
        ? 100.00
        : Math.max(...data?.map((item) => item[dataKey])) || 0;
    return maxi;
  };

  render() {
    const { data, dataKey } = this.props;
    const maxValue = this.getMaxValue();

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 15,
            left: 0,
            bottom: 0,
          }}
          barSize={8}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            className="text-xs"
            interval={0}
            tick={<CustomizedAxisTick />}
          />
          <YAxis
            domain={
              dataKey === "Occupancy"
                ? [0, 100]
                : [0, Math.ceil(maxValue * 1.2)]
            }
            tickCount={11}
            className=" !pl-4"
            allowDataOverflow={true}
            tickFormatter={(tick) =>
              dataKey === "Occupancy" ? `${tick}%` : formatNumber(tick)
            }
          />
          <Tooltip formatter={(value, name) => [formatNumberWithCommas(value as number), (name as string)]} />
          <Legend content={() => null} />
          <Line type="monotone" dataKey={dataKey} stroke="#41645c" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
