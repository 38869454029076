import React from "react";
import { Typography } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import GroupsIcon from "@mui/icons-material/Groups";
import HotelIcon from "@mui/icons-material/Hotel";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import TimelineIcon from "@mui/icons-material/Timeline";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import BedtimeOffIcon from "@mui/icons-material/BedtimeOff";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";


// Define a union type for the allowed keys
type ScoreCardKey =
  | "revenue"
  | "adr"
  | "occupancy"
  | "adjOccupancy"
  | "revpar"
  | "leadTime"
  | "los"
  | "blockedNights"
  | "ownerStays"
  | "activeListings";

// Define the icon map with ScoreCardKey type keys
const iconMap: Record<ScoreCardKey, typeof AttachMoneyIcon> = {
  revenue: AttachMoneyIcon,
  adr: PriceChangeIcon,
  occupancy: GroupsIcon,
  adjOccupancy: HotelIcon,
  revpar: CurrencyExchangeIcon,
  leadTime: TimelineIcon,
  los: TimelapseIcon,
  blockedNights: BedtimeOffIcon,
  ownerStays: PersonIcon,
  activeListings: ApartmentIcon,
};

const ScoreCard: React.FC<{
  data: { key: ScoreCardKey; value: string; label: string }[];
}> = ({ data }) => (
  <div className="flex flex-col space-y-5 w-full py-3">
    <div className="flex inline-flex space-x-4 w-full justify-between items-center">
      {data.map((item) => {
        const Icon = iconMap[item.key];
        return (
          <div
            key={item.key}
            className="w-56 shadow-lg rounded-lg h-full flex flex-col-2 items-center p-3 bg-white"
          >
            <div className="items-center h-full w-fit border !border-[#0066b2] rounded-3xl p-2">
            {Icon ? <Icon style={{ color: "#0066b2" }} /> : null}
            </div>
            <div className="w-2/3 h-full items-center pl-2">
              <Typography
                variant="h6"
                className="!font-semibold !text-black text-center !text-base text-nowrap"
              >
                {item.value}
              </Typography>
              <Typography className="!font-medium !text-gray-600 text-center !text-xs">
                {item.label}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default ScoreCard;
