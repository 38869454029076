import React, { useState, useEffect } from "react";
import { DatePicker, Select, Space } from "antd";
import type { DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);
const { Option } = Select;
type RangeType =
  | "MTD"
  | "YTD"
  | "QTD"
  | "Current Year"
  | "Last Year"
  | "Custom";
interface RangePickerProps {
  onChange?: (range: { startDate: Dayjs; endDate: Dayjs } | null) => void;
  defaultType?: RangeType;
  dateRange: {
    startDate: Dayjs;
    endDate: Dayjs;
  } | null;
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: Dayjs;
      endDate: Dayjs;
    } | null>
  >;
}
const getDateRange = (rangeType: RangeType) => {
  const today = dayjs();
  let startDate: Dayjs;
  let endDate = today;
  switch (rangeType) {
    case "MTD":
      startDate = today.startOf("month");
      break;
    case "YTD":
      startDate = today.startOf("year");
      break;
    case "QTD":
      startDate = today.startOf("quarter");
      break;
    case "Current Year":
      startDate = today.startOf("year");
      endDate = today.endOf("year");
      break;
    case "Last Year":
      startDate = today.subtract(1, "year").startOf("year");
      endDate = today.subtract(1, "year").endOf("year");
      break;
    default:
      startDate = today;
  }
  return { startDate, endDate };
};
const RangePicker: React.FC<RangePickerProps> = ({
  onChange,
  dateRange,
  setDateRange,
}) => {
  const [type, setType] = useState<RangeType | undefined>("Current Year");
  useEffect(() => {
    if (onChange) {
      onChange(dateRange);
    }
  }, [dateRange, onChange]);
  const handleSelectChange = (value: RangeType | undefined) => {
    setType(value);
    if (value && value !== "Custom") {
      const range = getDateRange(value);
      setDateRange(range);
    } else {
      setDateRange(dateRange);
    }
  };
  const handleDateChange: DatePickerProps["onChange"] = (dates: any) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange({ startDate: dates[0], endDate: dates[1] });
      setType("Custom");
    } else {
      setDateRange(null);
    }
  };
  return (
    <Space>
      <Select
        value={type}
        onChange={handleSelectChange}
        style={{ width: 160 }}
        placeholder="Quick Dates"
      >
        <Option value="MTD">Month-to-Date</Option>
        <Option value="YTD">Year-to-Date</Option>
        <Option value="QTD">Quarter-to-Date</Option>
        <Option value="Current Year">Current Year</Option>
        <Option value="Last Year">Last Year</Option>
        <Option value="Custom">Custom</Option>
      </Select>
      <DatePicker.RangePicker
        value={dateRange ? [dateRange.startDate, dateRange.endDate] : null}
        onChange={handleDateChange as any}
        disabled={type !== "Custom"}
        allowEmpty={false}
        allowClear={false}
      />
    </Space>
  );
};
export default RangePicker;