import React, { useEffect } from "react";
import { InputNumber, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Typography } from "@mui/material";
import MonthPicker from "../../../components/MonthPicker";
import useFilters from "../../../hooks/customFilters";
import dayjs, { Dayjs } from "dayjs";

interface MonthlyData {
  id: number;
  [key: string]: string | number;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const data: MonthlyData[] = [
  {
    id: 1,
    January: 100,
    February: 200,
    March: 150,
    April: 300,
    May: 120,
    June: 180,
    July: 250,
    August: 130,
    September: 210,
    October: 190,
    November: 220,
    December: 240,
  },
];

const PMFeesMonthlyTable: React.FC<{ propertyID: string | number | null; isEditing: boolean }> = ({ propertyID, isEditing }) => {
  
  const { selection, selectedDate, setSelection, setSelectedDate } = useFilters();

  useEffect(() => {
    if (!selectedDate) {
      const currentYear = dayjs().year(); 
      setSelectedDate(dayjs().year(currentYear));
    }
  }, [selectedDate, setSelectedDate]);

  const columns: ColumnsType<MonthlyData> = months.map((month) => ({
    title: month,
    dataIndex: month,
    key: month,
    width: 100,
    align: "center",
    render: (text) =>
      isEditing ? (
        <InputNumber defaultValue={Number(text)} style={{ width: "65px" }}/>
      ) : (
        text
      ),
  }));

  return (
    <div>
      <div className="w-full flex flex-cols-2 justify-between pb-4 items-center">
        <Typography variant="subtitle1" className="!text-black ">
          PM Fees
        </Typography>
        <div className="!w-fit">
          <MonthPicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            isMonthly={selection === "year"}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="month"
        bordered
        pagination={false}
        className="border-t-2 p-0"
      />
      
    </div>
  );
};

export default PMFeesMonthlyTable;
