import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const HomeSkeleton: React.FC = () => {
  return (
    <Box className="grid w-full grid-cols-5 gap-3">
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
    <Skeleton className="min-h-16 rounded-md" variant="rectangular" />
  </Box>
  );
};

export default HomeSkeleton;

