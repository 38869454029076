import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import {
  Account,
  AuthenticationContext,
  SessionContext,
  Router,
  Session,
} from "@toolpad/core";
import store, { RootState } from "../../redux/store";
import { NAVIGATION } from "./utils";
import DashboardRoutes from "./DashboardRoutes";
import { createTheme, Button } from "@mui/material";
import { clearTokens } from "../../redux/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import CustomMenu from "./CustomMenu";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import logo from "../../assets/media/vividity main.png";

export default function DashboardLayoutBasic() {
  const [session, setSession] = useState<Session | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const isActive = useSelector(
    (state: RootState) => state.auth.user?.isActive || false
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!accessToken || !isActive) {
      navigate("/register");
    }
  }, [accessToken, isActive, navigate]);

  useEffect(() => {
    if (user) {
      setSession({
        user: {
          name: user.name,
          email: user.email,
        },
      });
    } else {
      setSession(null);
    }
  }, [user]);

  const navTheme = createTheme({
    cssVariables: {
      colorSchemeSelector: "data-toolpad-color-scheme",
    },
    colorSchemes: { light: true },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 600,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const authentication = React.useMemo(
    () => ({
      signIn: () => setSession({}),
      signOut: () => setModalOpen(true),
    }),
    []
  );

  const handleSave = () => {
    setSession(null);
    store.dispatch(clearTokens());
    window.location.href = "/login";
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const router = React.useMemo<Router>(
    () => ({
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => {
        setPathname("dashboard"+String(path));
        navigate(path);
      },
    }),
    [pathname, navigate]
  );

  return (
    <>
      <div
        className="w-fit ml-auto min-h-fit"
        style={{ zIndex: 1210, position: "fixed", top: 14, right: 5 }}
      >
        <AuthenticationContext.Provider value={authentication}>
          <SessionContext.Provider value={session}>
            <Account
              slots={{
                menuItems: CustomMenu,
              }}
              style={{ zIndex: 9999 }}
            />
          </SessionContext.Provider>
        </AuthenticationContext.Provider>
      </div>

      <AppProvider
        session={session}
        navigation={NAVIGATION}
        branding={{
          title: "",
          logo: (
            <img
              src={logo}
              alt="Vividity Logo"
              className="pl-4"
              style={{ height: 40 }}
            />
          ),
        }}
        router={router}
        theme={navTheme}
      >
        <DashboardLayout defaultSidebarCollapsed={true}>
          <DashboardRoutes />
        </DashboardLayout>
      </AppProvider>
      <Modal open={modalOpen} onClose={handleClose}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="bg-white p-8 rounded-md shadow-lg w-[25%] max-w-4xl space-y-4">
            <Typography className="!text-gray-500 text-lg">
              Are you sure you want to logout?
            </Typography>
            <div className="flex justify-between mt-7 px-10 bg-white text-black">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Yes
              </LoadingButton>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
