import apiClient from "./apiClient";

const fetch = (
  page = 1,
  per_page = 10,
  activeSelection = "active",
  searchValue = ""
) =>
  apiClient.get(
    `/api/properties/targets?tab=${activeSelection}&search=${searchValue}&page=${page}&per_page=${per_page}`
  );

const fetchPropertyTarget = async ({
  propertyID,
  params,
}: {
  propertyID: string | number;
  params: any;
}) => {
  return await apiClient.get(`/api/revenue-targets/${propertyID}/`, { params });
};

const update = ({
  propertyID,
  payload,
}: {
  propertyID: string | number;
  payload: any;
}) =>
  apiClient.post(
    `/api/revenue-targets/${propertyID}/update`,
    payload
  );

  const customPMFeesTable = ({
    propertyID,
    payload,
  }: {
    propertyID: string | number;
    payload: any;
  }) =>
    apiClient.get(
      `/api/pmfees/${propertyID}/`,
      payload
    );

export { fetch, fetchPropertyTarget, update, customPMFeesTable };
