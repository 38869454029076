import type { ColumnsType } from "antd/es/table";
import { TableData } from "./types";
import { Tag } from "antd";
import { CONVERT_CURRENCY_CODE } from "../utils";
import NumberConverter from "../../../utils/NumberFormatter";

export const BOOKING_COLUMNS: ColumnsType<TableData> = [
  {
    title: "ID",
    dataIndex: "pms_table_reservationid",
    rowScope: "row",
    className: "break-words",
    width: "10%",
  },
  {
    title: "Guest",
    dataIndex: "guest",
    key: "guest",
    width: "15%",
  },
  {
    title: "Channel",
    dataIndex: "channel",
    key: "channel",
    width: "10%",
  },
  {
    title: "Booking Date",
    dataIndex: "formatted_reservation_datetime",
    key: "formatted_reservation_datetime",
    width: "10%",
  },
  {
    title: "Check-in Date",
    dataIndex: "arrival_date",
    key: "arrival_date",
    width: "10%",
  },
  {
    title: "Check-out Date",
    dataIndex: "departure_date",
    key: "departure_date",
    width: "10%",
  },
  {
    title: "Nights",
    dataIndex: "nights",
    key: "nights",
    width: "5",
  },
  {
    title: "Reservation Status",
    dataIndex: "reservation_status",
    key: "reservation_status",
    width: "15%",
    render: (tag) => {
      let color = "cyan";
      if (tag === "modified") {
        color = "geekblue";
      }
      if (tag === "new") {
        color = "lightgreen";
      }
      if (tag === "ownerStay") {
        color = "lime";
      }
      if (tag === "cancelled") {
        color = "volcano";
      }
      return (
        <Tag color={color} key={tag} className="!text-wrap break-all">
          {tag?.toString()?.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Listing",
    dataIndex: "listing",
    key: "listing",
    width: "20%",
    render: (text) => <span className="text-wrap">{text}</span>,
  },
  {
    title: "Net Revenue",
    dataIndex: "net_revenue",
    key: "net_revenue",
    width: "15%",
    render: (revenue, record) => (
      <>
        {CONVERT_CURRENCY_CODE(record?.currency?.toString()) || ""} <NumberConverter value={revenue} />
      </>
    ),
  },
  {
    title: "Gross Revenue",
    dataIndex: "gross_revenue",
    key: "gross_revenue",
    width: "15%",
    render: (revenue, record) => (
      <>
        {CONVERT_CURRENCY_CODE(record?.currency?.toString()) || ""} <NumberConverter value={revenue} />
      </>
    ),
  },
  {
    title: "ADR(Net)",
    dataIndex: "adr",
    key: "adr",
    width: "15%",
    render: (adr, record) => (
      <>
        {CONVERT_CURRENCY_CODE(record?.currency?.toString()) || ""} <NumberConverter value={adr} />
      </>

    ),
  },
];
