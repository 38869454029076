import React, { useState } from "react";
import { Table, Input, Button, InputNumber } from "antd";
import { createStyles } from "antd-style";
import type { ColumnsType } from "antd/es/table";
import ToggleButton from "../../../components/ToggleButton";
import { REVENUETYPES } from "../portfolio/utils";

import MonthPicker from "../../../components/MonthPicker";
import useFilters from "../../../hooks/customFilters";

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { TextField } from "@mui/material";
import PMFeesMonthlyTable from "./PMFeesMonthlyTable";
import TargetMonthlyTable from "./TargetMonthlyTable";

import { customPMFeesTable } from "../../../services/targetAPI";
import dayjs from "dayjs";

interface Property {
  id: number | string | any;
  internal_listing_name: string;
  start_date: string;
  end_date: string;
  pm_fees: number | string;
  is_pm_fees_from_net: boolean;
}

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table-row-expand-icon-cell {
      width: 30px !important;
    }
  `,
}));

const ConfigTable: React.FC<{
  data: Property[];
  isLoading: boolean;
  propertyID: string | number;
}> = ({ data, isLoading, propertyID }) => {
  const { styles } = useStyle();
  const [revenueSelection, setRevenueSelection] = useState<string>("net");
  const [isEditing, setIsEditing] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const [monthValues, setMonthValues] = useState<{ [key: string]: number }>({});
  const [selectedRowID, setSelectedRowID] = useState<string | number>("");
  const [selectedRowName, setSelectedRowName] = useState<string | null>(null);
  const [pmsFees, setPmsFees] = useState<string | string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const { selection, selectedDate, setSelection, setSelectedDate } =
    useFilters();

  // const fetchCustomPMFees = async (dates: any) => {
  //   const [start, end] = dates?.map((date: any) => dayjs(date)) || [null, null];
  //   const startMonth = dayjs(start).format("YYYY-MM");
  //   const endMonth = dayjs(end).format("YYYY-MM");

  //   try {
  //     const response = await customPMFeesTable({
  //       propertyID,
  //       payload: {
  //         start_month: startMonth,
  //         end_month: endMonth,
  //       },
  //     });
  //     const data = response.data;
  //     setSelectedRowID(data?.id);
  //     console.log(response, "reponse");
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //   }
  // };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  // const handleExpandAll = () => {
  //   setExpandedKeys(expandedKeys.length ? [] :data.map((item) => item.id));
  // };

  const baseColumns: ColumnsType<Property> = [
    // {
    //   title: (
    //     <span onClick={handleExpandAll} style={{ cursor: "pointer" }}>
    //       {expandedKeys.length ? <UpOutlined /> : <DownOutlined />}
    //     </span>
    //   ),
    //   dataIndex: "expandIcon",
    //   key: "expandIcon",
    // },
    {
      title: "Unit",
      dataIndex: "internal_listing_name",
      key: "internal_listing_name",
      width: "25%",
      render: (text) => text,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "20%",
      align: "center",
      render: (text, record) =>
        isEditing ? (
            <MonthPicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              isMonthly={selection === "month"}
            />
        ) : (
          text
        ),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "20%",
      align: "center",
      render: (text, record) =>
        isEditing ? (
          <MonthPicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            isMonthly={selection === "month"}
          />
        ) : (
          text
        ),
    },
    {
      title: "Standard PM Fees",
      dataIndex: "pm_fees",
      key: "pm_fees",
      width: "15%",
      align: "center",
      render: (text, record) =>
        isEditing ? (
          <InputNumber
            defaultValue={text}
            // onChange={(e) => (record.pm_fees = e.target.value)}
            placeholder="0"
            style={{ width: "80px" }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Net/Gross",
      dataIndex: "is_pm_fees_from_net",
      key: "is_pm_fees_from_net",
      width: "15%",
      align: "center",
      render: (text, record) =>
        isEditing ? (
          <ToggleButton
            selection={revenueSelection}
            setSelection={setRevenueSelection}
            Choices={REVENUETYPES}
          />
        ) : record.is_pm_fees_from_net !== null &&
          record.is_pm_fees_from_net == true ? (
          "Net"
        ) : record.is_pm_fees_from_net !== null &&
          record.is_pm_fees_from_net == false ? (
          "Gross"
        ) : (
          ""
        ),
    },
  ];

  const columns = [...baseColumns];

  const expandedRowRender = (record: Property) => {
    return (
      <>
        <div style={{ padding: "10px" }}>
          <PMFeesMonthlyTable
            propertyID={selectedRowID}
            isEditing={isEditing}
          />
        </div>
        <div style={{ padding: "10px" }}>
          <TargetMonthlyTable
            propertyID={selectedRowID}
            isEditing={isEditing}
          />
        </div>
      </>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Edit button at the top right corner */}
      <div className="pb-3 h-fit text-end w-full">
        <Button
          onClick={handleEditToggle}
          style={{
            background: isEditing ? "#faad14" : "#1890ff",
            color: "#fff",
          }}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          bordered
          className={`font-semibold ${styles.customTable}`}
          pagination={false}
          loading={isLoading}
          expandable={{
            expandedRowRender,
            expandRowByClick: false,
            expandedRowKeys: expandedKeys,
            expandIconColumnIndex: 0,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                    // fetchCustomPMFees(e);
                  }}
                />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ),
            onExpand: (expanded, record) => {
              setExpandedKeys((prev) =>
                expanded
                  ? [...prev, record.id]
                  : prev.filter((id) => id !== record.id)
              );
            },
          }}
        />
      </div>
      <style>
        {`
          .ant-table-row-expand-icon-cell {
            max-width: 10px !important; /* Adjust this value to make it narrower */
          }

          .ant-table-cell {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        `}
      </style>
    </div>
  );
};

export default ConfigTable;
