import React, { useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  Button,
  IconButton,
  Drawer,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
} from "@mui/material";
import { createStyles } from "antd-style";

import { RevenueType } from "./types";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import TargetSettings from "./TargetSettings";

import { update } from "../../../services/targetAPI";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Chip from "@mui/material/Chip";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

interface Property {
  id: number;
  internal_listing_name: string;
  currency?: string;
  revenues: RevenueType[];
  name?: string;
}

// Custom styling for the table
const useStyle = createStyles(({ css }) => ({
  customTable: css`
    antd-table {
      antd-table-container {
        antd-table-body,
        antd-table-content {
          scrollbar-width: thin;
          scrollbar-color: unset;
        }
      }
    }
  `,
}));

const TargetTable: React.FC<{
  data: Property[];
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  fetchTargets: () => Promise<void>;
}> = ({ data, isLoading, fetchTargets }) => {
  const [monthValues, setMonthValues] = useState<{ [key: string]: number }>({});
  const [pmsFees, setPmsFees] = useState<string | string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [error, setError] = useState(false);

  const [selectedRowID, setSelectedRowID] = useState<string | number | null>(
    null
  );
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [selectedRowName, setSelectedRowName] = useState<string | null>(null);
  const [revenueSelection, setRevenueSelection] = useState<string>("");

  const { styles } = useStyle();

  const baseColumns: ColumnsType<Property> = [
    {
      title: "UID",
      dataIndex: "internal_listing_name",
      key: "internal_listing_name",
      width: "20%",
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "15%",
      align: "center",
    },
    {
      title: "Bedroom",
      dataIndex: "bedroom",
      key: "bedroom",
      width: "10%",
      align: "center",
    },
    {
      title: "To Do",
      dataIndex: "to_do_flag",
      key: "to_do_flag",
      width: "15%",
      align: "center",
      render: (text, record) => {
        return (
          <span className="flex items-center justify-center">
            {text == true ? (
              <CheckCircleOutlineIcon style={{ color: "rgb(34, 197, 94)" }} />
            ) : (
              <Chip label={text} color="info" size="small" variant="outlined"
              onClick={(event) => handleMenuClick(event, record)} />
            )}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "button",
      key: "button",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <IconButton
          onClick={(event) => handleMenuClick(event, record)}
          aria-label="settings"
          size="small"
        >
          <SettingsIcon style={{ color: '#0066b2' }} />
        </IconButton>
      ),
    },
  ];

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    record: Property
  ) => {
    setPmsFees("");
    setStartDate("");
    setEndDate("");
    setRevenueSelection("");

    setSettingsOpen(true);
    setSelectedRowID(record?.id);
    setSelectedRowName(record?.internal_listing_name || "");

    // setSelectedMonths(record.revenues.map((rev) => rev.month));
  };

  const handleValueChange = (month: string, value: number) => {
    setMonthValues((prev) => ({ ...prev, [month]: value }));
  };

  // Close settings drawer
  const handleClose = () => {
    setSettingsOpen(false);
  };

  const handleSave = async () => {
    if (pmsFees && !revenueSelection) {
      setError(true);
      return;
    }

    setError(false);
    if (selectedRowID) {
      try {
        const response = await update({
          propertyID: selectedRowID,
          payload: {
            monthValues,
            pmsFees,
            startDate,
            endDate,
            revenueSelection,
          },
        });
        handleClose();
        fetchTargets()
      } catch (error) {
        console.error("Error saving property targets:", error);
      } finally {
      }
    } else {
      console.error("Property ID is not defined");
    }
  };

  const columns = [...baseColumns];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        bordered
        className={`font-semibold ${styles.customTable}`}
        pagination={false}
        loading={isLoading}
      />

      {/* Drawer or Modal for showing inner page content */}
      <Drawer
        anchor="right"
        open={settingsOpen}
        onClose={handleClose}
        PaperProps={{ style: { width: "50%", overflowY: "auto" } }}
        className="ml-auto !shadow-none"
      >
        <div className="relative top-16 py-3 w-full !min-h-fit space-y-6 !shadow-none  !bg-gray-100 px-5">
          <div className="flex inline-flex justify-between w-full">
            <Typography variant="h4" className="!text-gray-500">
              Settings
            </Typography>

            <div className="flex w-full justify-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div>
            {selectedRowName !== "" ? (
              <div className="flex justify-start gap-4 w-full items-center">
                <b>Unit:</b> {selectedRowName}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex inline-flex w-full justify-start items-center flex-cols-2">
            <div className="w-1/2">
              <p>PM fees</p>
              <TextField
                variant="outlined"
                className="bg-white rounded-lg shadow-lg w-3/4"
                value={pmsFees}
                onChange={(e) => setPmsFees(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="w-1/2 justify-start h-full pl-3">
              <p>Commission on Net / Gross revenue</p>
              <RadioGroup
                row
                value={revenueSelection}
                onChange={(e) => {
                  setRevenueSelection(e.target.value);
                  setError(false);
                }}
              >
                <FormControlLabel
                  value="Net"
                  control={<Radio color="primary" />}
                  label="Net"
                />
                <FormControlLabel
                  value="Gross"
                  control={<Radio color="primary" />}
                  label="Gross"
                />
              </RadioGroup>
              {error && (
                <p style={{ color: "red", marginTop: "4px" }}>
                  Please select any one.
                </p>
              )}
            </div>
          </div>
          <hr className="bg-gray-300 pt-0" />
          <div className="flex space-x-2 w-full  flex-cols-2">
            {/* Start Date Field */}
            <div className="w-1/2">
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              className="bg-white w-3/4"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            </div>

            {/* End Date Field */}
            <div className="w-1/2">
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              className="bg-white w-3/4"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            </div>
          </div>
          <hr className="bg-gray-300 pt-0" />
            <TargetSettings
              monthValues={monthValues}
              handleValueChange={handleValueChange}
              propertyID={selectedRowID}
              setMonthValues={setMonthValues}
              selectedRowName={selectedRowName}
              setPmsFees={setPmsFees}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setRevenueSelection={setRevenueSelection}
            />
          <div className="flex flex-inline justify-between w-full px-6 py-2 align-right">
            <Button
              onClick={handleClose}
              variant="outlined"
              className="py-2 px-3 rounded-lg !font-medium !bg-gray-500 !text-white"
            >
              Close
            </Button>
            <Button
              className="p-2 rounded-lg !font-medium !bg-blue-700 !text-white"
              variant="outlined"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default TargetTable;