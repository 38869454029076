import { useEffect, useState } from "react";
import { Button, Card, TextField, IconButton, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { CircularProgress } from "@mui/material";

import { DatePicker } from "antd";
import dayjs from "dayjs";

import { fetchPropertyTarget } from "../../../services/targetAPI";
import { CONVERT_CURRENCY_CODE } from "../utils";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

const TargetSettings: React.FC<{
  monthValues: { [key: string]: number };
  handleValueChange: (month: string, value: number) => void;
  propertyID: string | number | null;
  setMonthValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
  selectedRowName: string | null;
  setPmsFees: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setRevenueSelection: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  monthValues,
  handleValueChange,
  propertyID,
  setMonthValues,
  setPmsFees,
  setStartDate,
  setEndDate,
  setRevenueSelection,
}) => {
  const currentYear = dayjs().year();
  const startOfYear = dayjs(`${currentYear}-01-01`);
  const endOfYear = dayjs(`${currentYear}-12-31`);
  const [responseValues, setReponseValues] = useState<{
    [key: string]: number;
  }>({});
  const [targetLoading, setTargetLoading] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(
    undefined
  );

  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);

  const handleMonthPeriodChange = async (dates: any) => {
    if (dates && propertyID) {
      const [start, end] = dates?.map((date: any) => dayjs(date)) || [
        null,
        null,
      ];
      const startMonth = dayjs(start).format("YYYY-MM");
      const endMonth = dayjs(end).format("YYYY-MM");

      try {
        setTargetLoading(true);
        const response = await fetchPropertyTarget({
          propertyID,
          params: {
            start_month: startMonth,
            end_month: endMonth,
          },
        });
        const data = response.data;
        if (data && data.reservations) {
          const res = data.reservations;
          const newMonthValues = res.reduce((acc: any, item: any) => {
            acc[`${item.month.toString().padStart(2, "0")}`] =
              item.target_amount;
            return acc;
          }, {});
          if (data.currency) {
            setSelectedCurrency(data.currency);
          }
          if (start && end) {
            const startMonth = start.startOf("month");
            const endMonth = end.endOf("month");
            const newSelectedMonths: string[] = [];

            for (
              let m = startMonth;
              m.isSameOrBefore(endMonth);
              m = m.add(1, "month")
            ) {
              const monthKey = m.format("YYYY-MM");
              newSelectedMonths.push(monthKey);
            }

            setSelectedMonths(newSelectedMonths);
          } else {
            setSelectedMonths([]);
          }
          setMonthValues(newMonthValues);
          setReponseValues(newMonthValues);

          setPmsFees(data?.pms_fees);
          setEndDate(data?.end_date);
          setStartDate(data?.start_date);
          if (data?.is_pm_fees_from_net === true) {
            setRevenueSelection("Net");
          } else{
            setRevenueSelection("Gross");
          }
        }
      } catch (error) {
        console.error("Error fetching property targets:", error);
      } finally {
        setTargetLoading(false);
      }
    }
  };

  const handleReset = () => {
    setMonthValues(responseValues);
  };

  useEffect(() => {
    handleMonthPeriodChange([startOfYear, endOfYear]);
  }, []);

  return (
    <div>
      <div>
        <Typography variant="h6">Targets</Typography>
      </div>
      <div className="w-full h-full flex justify-center items-center">
        <div className="rounded-md shadow-md w-full space-y-4">
          <div className="flex justify-between w-full">
            <div className="flex flex-col w-full pt-2">
              <Typography variant="subtitle2" className="!text-gray-500">
                Select Months
              </Typography>

              <div className="w-full">
                <div className="w-1/2">
                  <DatePicker.RangePicker
                    format="YYYY-MM"
                    defaultValue={[startOfYear, endOfYear]}
                    onChange={handleMonthPeriodChange}
                    picker="month"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    allowClear={false}
                    className="w-3/4 p-2"
                  />
                </div>
                <div className="w-1/2"></div>
              </div>
            </div>
          </div>
          {targetLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="max-h-80 overflow-y-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2 bg-gray-100">
                {selectedMonths.map((month) => (
                  <div key={month}>
                    <Card className="p-4">
                      <TextField
                        label={`${new Date(month).toLocaleString("default", {
                          month: "long",
                        })} ${new Date(month).getFullYear()}`} // Month and Year
                        type="number"
                        onChange={(e) =>
                          handleValueChange(month, parseFloat(e.target.value))
                        }
                        value={monthValues[month] || ""}
                        variant="outlined"
                        fullWidth
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                {CONVERT_CURRENCY_CODE(selectedCurrency || "")}
                              </InputAdornment>
                            ),
                            inputProps: { min: 0 },
                          },
                        }}
                      />
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-between mt-5 pb-2 pl-2">
            <Button variant="outlined" onClick={handleReset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetSettings;
