import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnGroupType, ColumnsType, ColumnType } from "antd/es/table";
import NumberConverter from "../../../utils/NumberFormatter";
import { createStyles } from "antd-style";
import { CONVERT_CURRENCY_CODE } from "../utils";
import { Typography } from "@mui/material";

interface Revenue {
  month: string;
  net_rev_amount: number;
  currency: string;
  net_comparison_val?: string;
  net_comparison_percent?: string;
}

interface Property {
  id: number;
  unit: string;
  currency: string;
  revenues: Revenue[];
  total_net_revenue: number | string;
  total_comparison_percent: {
    net_comparison_percent: string;
  };
  total_comparison_value: {
    net_comparison_value: string;
  };
}

const useStyle = createStyles(({ css, token }) => {
  return {
    customTable: css`
      antd-table {
        antd-table-container {
          antd-table-body,
          antd-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `,
  };
});

const baseColumns: ColumnsType<Property> = [
  {
    title: "Units",
    dataIndex: "unit",
    width: 200,
    key: "unit",
    fixed: "left",
  },
];

const totalColumn = (varianceSelection: string): ColumnsType<Property> => [
  {
    title: "Total",
    width: 100,
    align: "center",
    key: "total",
    fixed: "right",
    render: (_, record) => {
      const isPercent = varianceSelection === "%";

      const comparison = isPercent
        ? record?.total_comparison_percent?.net_comparison_percent
        : record?.total_comparison_value?.net_comparison_value;

      return (
        <div className="flex flex-col items-center">
          <div>
            {CONVERT_CURRENCY_CODE(record?.currency)}{" "}
            <NumberConverter value={record.total_net_revenue} />
          </div>
          {comparison && comparison !== "N/A" && (
            <div
              className={`font-semibold ${
                (isPercent && comparison.includes("-")) ||
                (!isPercent && parseFloat(comparison) < 0)
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              <Typography variant="caption">
                {!isPercent ? CONVERT_CURRENCY_CODE(record?.currency) : ""}{" "}
                <NumberConverter value={comparison} />
                {isPercent ? "%" : ""}
              </Typography>
            </div>
          )}
        </div>
      );
    },
  },
];

const getMonthColumns = (
  data: Property[],
  year: number,
  varianceSelection: string
): ColumnsType<Property> => {
  const allMonths = Array.from({ length: 12 }, (_, index) =>
    new Date(year, index, 2).toISOString().slice(0, 7)
  );

  const sortedMonths = allMonths.sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  return sortedMonths.map((month) => ({
    title: new Date(month).toLocaleString("default", { month: "long" }),
    dataIndex: "revenues",
    key: month,
    align: "center",
    render: (_, record: Property) => {
      const revenueForMonth = record?.revenues?.find(
        (rev) => rev.month === month
      );
      const isPercent = varianceSelection === "%";

      const comparison = isPercent
        ? revenueForMonth?.net_comparison_percent
        : revenueForMonth?.net_comparison_val;

      if (revenueForMonth) {
        return (
          <div className="flex flex-col items-center">
            <div>
              {CONVERT_CURRENCY_CODE(revenueForMonth?.currency)}{" "}
              <NumberConverter value={revenueForMonth.net_rev_amount} />
            </div>
            {comparison && comparison !== "N/A" && (
              <div
                className={`font-semibold ${
                  (isPercent && comparison.includes("-")) ||
                  (!isPercent && parseFloat(comparison) < 0)
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              >
                <Typography variant="caption">
                  {!isPercent
                    ? CONVERT_CURRENCY_CODE(revenueForMonth?.currency)
                    : ""}{" "}
                  <NumberConverter value={comparison} />
                  {isPercent ? "%" : ""}
                </Typography>
              </div>
            )}
          </div>
        );
      }
      return null;
    },
  }));
};

const RevenueTable: React.FC<{
  data: Property[];
  year: number;
  varianceSelection: string;
  isLoading: boolean;
}> = ({ data, year, varianceSelection, isLoading }) => {
  const [columns, setColumns] = useState<
    (ColumnGroupType<Property> | ColumnType<Property>)[]
  >([]);
  const { styles } = useStyle();

  useEffect(() => {
    const column = [
      ...baseColumns,
      ...getMonthColumns(data, year, varianceSelection),
      ...totalColumn(varianceSelection),
    ];
    setColumns(column);
  }, [varianceSelection, data, year]);

  return (
    <>
      {varianceSelection && (
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          bordered
          className={`font-semibold ${styles.customTable}`}
          pagination={false}
          scroll={{ x: "calc(980px + 70%)" }}
          loading={isLoading}
        />
      )}
    </>
  );
};

export default RevenueTable;
