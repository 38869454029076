import React from "react";
import { DatePicker } from "antd";
import "tailwindcss/tailwind.css";
import dayjs, { Dayjs } from "dayjs";

interface MonthPickerProps {
  selectedDate: Dayjs | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  isMonthly: boolean;
}

const MonthPicker: React.FC<MonthPickerProps> = ({
  selectedDate,
  setSelectedDate,
  isMonthly,
}) => {
  const formatValues: string = isMonthly ? "MMM YYYY" : "YYYY";

  // Handle date change
  const onChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  return (
    <div className="w-full">
      <DatePicker
        className="bg-white w-full"
        placeholder={isMonthly ? "Select Month" : "Select Year"}
        value={selectedDate}
        // style={{width: 100}}
        onChange={onChange}
        format={formatValues}
        picker={isMonthly ? "month" : "year"} // Choose month or year picker based on isMonthly
      />
    </div>
  );
};

export default MonthPicker;
