import React, { useMemo } from "react";
import { Table } from "antd";
import { createStyles } from "antd-style";
import type { ColumnsType } from "antd/es/table";

interface Property {
  id: number;
  name: string;
  revenue: number;
  gross_revenue: number;
  bookings: number;
}

interface BookingSourceTableProps {
  data: Property[];
  columns?: ColumnsType<Property>;
  revenueSelection?: string;
}

const useStyle = createStyles(({ css }) => {
  return {
    customTable: css`
      .ant-table-container {
        .ant-table-body,
        .ant-table-content {
          scrollbar-width: thin;
          scrollbar-color: unset;
        }
      }
    `,
  };
});

const BookingSourceTable: React.FC<BookingSourceTableProps> = ({
  data,
  revenueSelection = "net",
}) => {
  const { styles } = useStyle();

  const columns: ColumnsType<Property> = useMemo(
    () => [
      {
        title: "Source",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Revenue",
        dataIndex: revenueSelection === "net" ? "revenue" : "gross_revenue",
        key: "revenue",
      },
      {
        title: "Bookings",
        dataIndex: "bookings",
        key: "bookings",
      },
    ],
    [revenueSelection]
  );

  return (
    <div className="h-64 overflow-hidden overflow-y-auto mt-2 px-0 rounded-lg">
      <Table<Property>
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        className={`font-semibold h-fit mt-3 rounded-lg ${styles.customTable}`}
      />
    </div>
  );
};

export default BookingSourceTable;
