import apiClient from "./apiClient";

export type PerformanceParams = {
  start_date: string | undefined;
  end_date: string | undefined;
  page?: number;
  per_page?: number;
};

export const fetchPerformanceList = async ({
  params,
}: {
  params: PerformanceParams;
}) => {
  try {
    const response = await apiClient.get(`/api/performance/table-data/`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation list", error);
    throw error;
  }
};

export const fetchPerformanceScoreCard = async ({
  params,
}: {
  params: PerformanceParams;
}) => {
  try {
    const response = await apiClient.get(`/api/performance/cards-data/`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation list", error);
    throw error;
  }
};
