import React from "react";
import CustomLightTooltip from "../components/CustomLightTooltip";
import { formatNumberWithCommas } from "../pages/dashboard/utils";

interface NumberConverterProps {
  value: string | number;
}

const NumberConverter: React.FC<NumberConverterProps> = ({ value }) => {
  const formatNumber = (num: number): string => {
    if (Math.abs(num) >= 1_000_000) {
      return (num / 1_000_000)?.toFixed(1) + "M";
    } else if (Math.abs(num) >= 1_000) {
      return (num / 1_000)?.toFixed(1) + "k";
    }
    return num.toString();
  };

  // Ensure the value is a number before formatting
  const numericValue = typeof value === "string" ? parseFloat(value) : value;

  return (
    <CustomLightTooltip title={formatNumberWithCommas(numericValue?.toFixed(2))}>
      <span className="cursor-pointer">
        {isNaN(numericValue) ? "-" : formatNumber(numericValue)}
      </span>
    </CustomLightTooltip>
  );
};

export default NumberConverter;
